.container {
  width: 100%;
  height: 100%;

  .control {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .button {
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }

    .search {
      width: 700px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
  }

  .Table {
  }

  .TurnPages {

  }
}

.softBox {
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  &:nth-last-child(1){
    margin-bottom: 50px;
  }
  
  // justify-content: space-around;

  .software {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 100px; 
    margin-right: 8px; 
    
   

    img {
      margin: 5px 0;
      height: 50px;
      width: 50px;
    }

    .title {
      text-align: center;
      font-size: 16px;
      
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .topBar {
    height: 40px;
    width: 100%;
    background: #1890ff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      padding: 0 20px;
      line-height: 40px;
      color: #ffffff;
      font-size: 16px;
      cursor: default;
    }

    .topBarOption {
      max-width: 245px;
      padding: 0 20px;
      line-height: 40px;
      color: #ffffff;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: default;

      .name {
        margin-left: 10px;
        line-height: 40px;
        color: #ffffff;
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: default;
      }
    }
  }

  .context {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: row;
  }

}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: url("../../assets/img/qyb.jpg") center;

  .pageLogo {
    font-size: 55px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
  }

  .Login {
    width: 70%;
    height: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .LoginInput {
      width: 43%;
      height: 363px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 10px;

      .LoginText {
        color: #ffffff;
        user-select: none;
        font-size: 20px;
        font-weight: 700;
      }

      .InputOption {
        width: 45%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .InputTitle {
          user-select: none;
          width: 50px;
        }

        input {

        }
      }

      .LoginOption {
        height: 30px;
        color: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        span {
          color: #ffffff;
        }
      }
    }
  }

  .foot {
    width: 70%;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);

    .footOption {
      margin: 0 15px;
    }
  }

}

.softBox {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  &:nth-last-child(1){
    margin-bottom: 50px;
  }

  .software {
    display: flex;
    min-width: 100px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
     margin-right: 5px;
     margin-left: 5px;
    img {
      margin: 5px 0;
      height: 50px;
      width: 50px;
    }

    .title {
      text-align: center;
      font-size: 16px;
    }
  }
}

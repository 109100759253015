.Box {
  max-height: 600px;
  overflow: auto;

  .title {
    font-size: 17px;
    font-weight: 700;
  }
  .PlusCircleFilled{
    font-size: 40px;
    color: #1890ff;
    cursor: pointer;
    color: #1890ff;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      opacity: .8;
    }
  }
.close{
  font-size: 22px;
  position: absolute;
  right: -11px;
  top: -14px;
  cursor: pointer;
  color: #1890ff;
  &:hover{
    opacity: .8;
  }
}
  .infoBox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
.add{
  font-size: 20px;
  position: absolute;
  right: -15px;
  top: -13px;
}
    .part {
      display: flex;
      flex-direction: row;
      margin: 10px 0;
      align-items: center;

      .partName {
        display: block;
        min-width: 72px;
        height: 32px;
        line-height: 32px;
        // vertical-align: middle;
      }
    }

    .softBox {
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;

      .software {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

      }
    }
  }
}

.softwareContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;


  .imgAndTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    // img {
    // }

    .title {
      margin-left: 10px;
      font-size: 20px;
      padding: 0 15px;
      border-bottom: 1px solid #333333;
    }

  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .Line {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 50px;

      .lineName {
        min-width: 105px;
      }

      .chosePart {
        display: flex;
        flex-direction: row;
        width: calc(100% - 76px);
        align-items: center;
      }
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  min-width: 1090px;
}

input {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: 'iconfont';
  src: url('./assets/iconfont/iconfont.eot');
  src: url('./assets/iconfont/iconfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/iconfont/iconfont.woff2') format('woff2'),
  url('./assets/iconfont/iconfont.woff') format('woff'),
  url('./assets/iconfont/iconfont.ttf') format('truetype'),
  url('./assets/iconfont/iconfont.svg#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
